import { $document, $win } from '../utils/globals'
import AOS from 'aos';

let $accSection   = $('.accordion-members .accordion__section');
let $accContainer = $('.accordion-members .accordion__container');
let $accBody      = $('.accordion-members .accordion__body');

$('.js-accordion-members')
	.on('click', '.accordion__head', function(){
		const $thisSection   = $(this).parent();
		const $thisBody      = $thisSection.find('.accordion__body');
		const $thisContainer = $thisSection.siblings('.accordion__container');

		// Mobile
		if ($win.width() < 768) {
			$thisBody.slideToggle();
			$accBody.not($thisBody).slideUp();
		}

		// Desktop
		else {
			$accBody.removeAttr('style');
			$thisBody.css({ display: 'block' });
		}

		// Desktop and Mobile
		$accContainer.empty();

		if ($thisSection.is('.active')) {
			$thisContainer.removeClass('active');
		} else {
			const $content = $thisBody.clone().addClass('accordion__body--inner').removeAttr('style');
			$thisContainer.append($content).addClass('active');
		}

		// Sections Active State
		$thisSection.toggleClass('active');
		$accSection.not($thisSection).removeClass('active');

		AOS.refresh();
	})

