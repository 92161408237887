import { $win } from '../utils/globals'

function smoothScrollToService(id) {
	const $target = $(`[data-id="${id}"`);

	if ($target.length < 1) {
		return;
	}

	const elOffset = $target.offset().top;
	const elHeight = $target.height();
	const windowHeight = $win.height();
	let offset;

	if (elHeight < windowHeight) {
    	offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
  	}
  	else {
    	offset = elOffset;
  	}

	$('html, body').animate({
		scrollTop: offset,
	}, 1000);
}

$win.on('load', function() {
	smoothScrollToService(window.location.hash.replace('#', ''));
});

$('.nav-footer a').on('click', function(e) {
	const id = this.hash.replace('#', '').replace(/%20/g, '');
	const $target = $(`[data-id="${id}"`);

	if ($target.length < 1) {
		return;
	}

	smoothScrollToService(id);
});

$('.js-tile-work a').on('click', function(e) {
	const id = this.hash.replace('#', '').replace(/%20/g, '');
	const $target = $(`[data-id="${id}"`);

	if ($target.length < 1) {
		return;
	}

	smoothScrollToService(id);
});
