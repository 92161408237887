import { $win } from '../utils/globals'

function smoothScrollToSection(id) {
	const $target = $(`[data-id="${id}"`);

	if ($target.length < 1) {
		return;
	}

	const offset = $target.offset().top;
	const gutter = $('#wpadminbar').length ? $('#wpadminbar').outerHeight() : 0;

	$('html, body').animate({
		scrollTop: offset - gutter
	}, 1000);
}

$win.on('load', function() {
	smoothScrollToSection(window.location.hash.replace('#', ''));
});

$('.js-btn-scroll').on('click', function(e) {
	const id = this.hash.replace('#', '').replace(/%20/g, '');
	const $target = $(`[data-id="${id}"`);

	if ($target.length < 1) {
		return;
	}

	smoothScrollToSection(id);
});
