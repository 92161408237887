/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/init-sliders';
import './modules/accordions';
import './modules/tabs-members';
import './modules/mobile-navigation';
import './modules/init-aos';
import './modules/scroll-to-service';
import './modules/scroll-to-section';

