import { $document } from '../utils/globals'

$('.js-tabs-members .tabs__head a').on('click',function(e){
	e.preventDefault();
	const target = $(this).attr('href');

	$(this)
		.parent()
			.addClass('is-current')
			.siblings()
				.removeClass('is-current');

	$(target)
		.addClass('is-current')
		.siblings()
			.removeClass('is-current');
});
