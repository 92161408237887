import Swiper from 'swiper/js/swiper';

$('.js-slider-works .swiper-container').each((i, slider) => {
      const $slider = $(slider).closest('.js-slider-works');
      const nextEl = $slider.find('.slider__btn--next');
      const prevEl = $slider.find('.slider__btn--prev');

      const swiper = new Swiper(slider, {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: false,
            breakpoints: {
              768: {
                slidesPerView: 'auto',
                spaceBetween: 15,
              },
              1200: {
                slidesPerView: 'auto',
                spaceBetween: 24,
              },
            },
             navigation: {
                  nextEl,
                  prevEl,
            },
      });
});

$('.js-slider-images .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-images');

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        resistanceRatio: 0,
        loop: true,
        speed: 1200,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
});

$('.js-slider-hero .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-hero');

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        resistanceRatio: 0,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        speed: 1500,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
});

$('.js-slider-people .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-people');

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        resistanceRatio: 0,
        loop: true,
        speed: 1200,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });
});
